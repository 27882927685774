import React, { useContext, useMemo, useState, useEffect } from 'react'
import TablePairs from '../../components/pages/liquidity/tablePairs'
import StyledButton from '../../components/common/Buttons/styledButton'
import Toggle from '../../components/common/Toggle'
import { useNavigate } from 'react-router-dom'
import { Strategies } from '../../config/constants/strategies'
import MobileFilterModal from '../../components/common/MobileFilterModal'
import { useAllHarvest } from '../../hooks/useGauge'
import { PoolTypes } from '../../config/constants'
import { ZERO_ADDRESS } from '../../utils/formatNumber'
import { FusionsContext } from '../../context/FusionsContext'
import DropDown from '../../components/common/Dropdown'
import InfoBanner from '../../components/pages/liquidity/infoBanner'
import { TransactButton } from '../../components/common/Buttons/transactButton'
import { STABLE_TOKENS } from '../../config/constants'

const sortOptions = [
  {
    label: 'APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'Total Staked',
    value: 'tvl',
    isDesc: true,
  },
  // {
  //   label: 'My Pool',
  //   value: 'pool',
  //   isDesc: true,
  // },
  {
    label: 'My Stake',
    value: 'stake',
    isDesc: true,
  },
  {
    label: 'Earnings',
    value: 'earn',
    isDesc: true,
  },
]

const Index = () => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  const strategists = useMemo(() => {
    const strategiesForChain = Strategies[chainId] || []
    const strategistsSet = new Set(strategiesForChain.map((strategy) => strategy.strategist))
    return ['All', ...Array.from(strategistsSet)]
  }, [chainId])
  const [filter, setFilter] = useState(PoolTypes.ALL)
  const [strategistFilter, setStrategistFilter] = useState('All')
  const [mobileFilter, setMobileFilter] = useState(false)
  const [isStaked, setIsStaked] = useState(false)
  const [isInactive, setIsInactive] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState({})
  const fusions = useContext(FusionsContext)
  const { onAllHarvest } = useAllHarvest()
  const [loading, setLoading] = useState(true)
  const [banner, setBanner] = useState(true)

  useEffect(() => {
    if (fusions.length > 0) {
      setLoading(false)
    }
  }, [fusions])

  useEffect(() => {
    if (filter === PoolTypes.FUSION) {
      setStrategistFilter('All')
    }
  }, [filter])

  const filteredPairs = useMemo(() => {
    let final
    if (isInactive) {
      final = fusions.filter((ele) => ele.gauge.apr.isZero())
    } else {
      final = fusions.filter((ele) => !ele.gauge.apr.isZero())
    }
    const result = final.filter((item) => {
      const isCorrect = item.gauge.address !== ZERO_ADDRESS && item.isValid
      return isCorrect && ((isStaked && !item.account.gaugeBalance.isZero()) || !isStaked)
    })
    let res = filter === PoolTypes.ALL ? result : result.filter((item) => item.kind === filter)
    if (filter === PoolTypes.FUSION) {
      res = result
      if (strategistFilter !== 'All') {
        res = res.filter((item) => item.strategist === strategistFilter)
      }
    }

    if (filter === PoolTypes.STABLE) {
      res = res.filter((item) => {
        const token0Address = item.token0?.address?.toLowerCase()
        const token1Address = item.token1?.address?.toLowerCase()
        const stableTokenAddresses = Object.values(STABLE_TOKENS).map((address) => address.toLowerCase())

        return (
          typeof STABLE_TOKENS === 'object' &&
          STABLE_TOKENS !== null &&
          stableTokenAddresses.includes(token0Address) &&
          stableTokenAddresses.includes(token1Address)
        )
      })
    }
    if (filter === PoolTypes.CORRELATED) {
      res = result.filter((item) => {
        if (!item.token0 || !item.token1 || item.token0.price === 0 || item.token1.price === 0) return
        const result = item.token0.price / item.token1.price
        return result >= 0.9 && result <= 1.1
      })
    }
    if (!searchText || searchText === '') {
      return res
    }
    return (
      res &&
      res.filter((item) => {
        const withSpace = item.symbol.replace('/', ' ')
        const withComma = item.symbol.replace('/', ',')
        return (
          item.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
          withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
          withComma.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    )
  }, [fusions, filter, strategistFilter, searchText, isStaked, isInactive, strategists])

  const sortedPairs = useMemo(() => {
    return filteredPairs.sort((a, b) => {
      let res
      switch (sort.value) {
        case 'apr':
          res = a.gauge.apr
            .minus(b.gauge.apr)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'tvl':
          res = a.gauge.tvl
            .minus(b.gauge.tvl)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'pool':
          res = a.account.totalUsd
            .minus(b.account.totalUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'stake':
          res = a.account.stakedUsd
            .minus(b.account.stakedUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'earn':
          res = a.account.earnedUsd
            .minus(b.account.earnedUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break

        default:
          break
      }
      return res
    })
  }, [filteredPairs, sort])

  const earnedPairs = useMemo(() => {
    return fusions.filter((pair) => !pair.account.earnedUsd.isZero())
  }, [fusions, isInactive])

  const navigate = useNavigate()

  const handlePopup = () => {
    navigate('/liquidity/fusion')
  }

  return (
    <>
      <div className='max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 xl:px-0 pt-20 pt-[160px] mx-auto'>
        <div className='lg:flex items-end justify-between'>
          <div className='max-w-[425px] w-full'>
            <h1 className='text-[34px] md:text-[42px] font-semibold text-white f-f-fg'>Liquidity</h1>
            <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
              Pair your tokens to provide liquidity. Stake the LP tokens to earn oLYNX.{' '}
              <a href='https://lynex.gitbook.io/lynex/' target='_blank' rel='noreferrer'>
                <span className='!text-lg text-themeOrange'>Learn More</span>
              </a>
            </p>
          </div>
          <div className='flex flex-col lg:flex-row justify-between mt-4'>
            <div className='w-full mt-3 lg:mt-0 flex lg:items-center justify-end space-x-3 lg:space-x-5'>
              <div className='border border-themeOrange w-full rounded-[3px] flex items-center lg:max-w-[250px] relative'>
                <img className='pointer-events-none absolute left-3 w-6 h-6' alt='' src='/images/svgs/search.svg' />
                <input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder='Search Pair or Token'
                  className='bg-white/80 placeholder-[#757384] h-[42px] w-full text-themeBlack text-sm xl:text-base pl-10 xl:pl-[41px] pr-4 py-3 md:py-[18px] rounded-[3px]'
                />
              </div>
              <StyledButton
                content={'ADD/REMOVE LIQUIDITY'}
                className={'hidden lg:flex text-sm w-[290px] h-[44px] py-[12px] px-3 lg:px-7'}
                onClickHandler={handlePopup}
              />
              {/* filter button for mobile */}
              <button
                onClick={() => {
                  setMobileFilter(!mobileFilter)
                }}
                className='w-12 flex-shrink-0 h-[42px] lg:hidden'
              >
                <img alt='' className='w-12 h-[42px]' src='/images/liquidity/filter.svg' />
              </button>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-between w-full mt-[16px] lg:space-x-8 xl:space-x-[60px] relative'>
          {/* for desktop */}
          <div className='w-full hidden lg:flex items-center space-x-8'>
            <DropDown options={Object.values(PoolTypes)} selected={filter} setSelected={setFilter} capitalized />
            {filter === PoolTypes.FUSION && <DropDown options={strategists} selected={strategistFilter} setSelected={setStrategistFilter} capitalized />}
            <div className='flex items-center space-x-2'>
              <Toggle checked={isStaked} onChange={() => setIsStaked(!isStaked)} toggleId='isStaked' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Staked Only</p>
            </div>
            <div className='flex items-center space-x-2'>
              <Toggle checked={isInactive} onChange={() => setIsInactive(!isInactive)} toggleId='isInactive' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Inactive Gauges</p>
            </div>
          </div>

          {/* mobile filters popup */}
          {mobileFilter && (
            <MobileFilterModal
              setMobileFilter={setMobileFilter}
              setFilter={setFilter}
              filter={filter}
              tabs={Object.values(PoolTypes)}
              isStaked={isStaked}
              setIsStaked={setIsStaked}
              isInactive={isInactive}
              setIsInactive={setIsInactive}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
              isLiquidity={true}
              strategists={strategists}
              strategistFilter={strategistFilter}
              setStrategistFilter={setStrategistFilter}
            />
          )}
          <div className='mt-3 lg:mt-0 w-full flex-col flex md:flex-row justify-end items-center space-y-3 md:space-y-0 md:space-x-3 '>
            <TransactButton
              disabled={earnedPairs.length === 0}
              onClickHandler={() => {
                onAllHarvest(earnedPairs)
              }}
              content={`CLAIM ALL (${earnedPairs.length})`}
              className='text-sm w-full md:max-w-1/2 lg:max-w-[290px] h-[44px] py-[12px] px-3 xl:px-7'
            />
            <StyledButton
              content={'ADD/REMOVE LIQUIDITY'}
              className={'lg:hidden text-sm w-full md:max-w-1/2 xl:max-w-[290px] h-[44px] py-[12px] px-3 xl:px-7'}
              onClickHandler={handlePopup}
            />
          </div>
        </div>
        {banner && (
          <InfoBanner
            logo='https://docs.linea.build/img/favicon.ico'
            title={`Deposit LPs to earn LXP-L points on Linea's surge campaign!`}
            url='https://referrals.linea.build/?refCode=oGi7YJuUKT'
            setBanner={setBanner}
          />
        )}
        <TablePairs
          pairsData={sortedPairs}
          loading={loading}
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          filter={filter}
          searchText={searchText}
          isStaked={isStaked}
          isInactive={isInactive}
        />
      </div>
    </>
  )
}

export default Index
